/*
 * @Author: dengqiuju@baidu.com
 * @Date: 2021-12-02 22:56:36
 * @Description: 订单管理相关接口
 */

import request from '@/request/request'
import { domainName } from '@/config'

//订单列表
// export const GetOrdersApi = (params) => request.post(`/admin/order/info/getOrders`, params);
export const GetOrdersApi = (params) => request.post(`/adminv2/order/info/getOrders`, params);
// 获取订单详情
export const GetOrdersInfoApi = (params) => request.get(`/admin/order/info`, { params });
// 修改订单收货地址
export const SaveOrderReceiptAddressApi = (params) => request.post(`/admin/order/info/saveExpress`,  params);
// 保存订单快递单号
export const GetOrdersExpressNoApi = (params) => request.get(`/admin/order/info/saveExpressNo`, { params });
// 订单申请退款
// export const OrderInfoApplyRefundApi = (params) => request.get(`/admin/order/info/apply/refund`, { params });
// 驳回退款申请
// export const OrderInfoRefuseRefundApi = (params) => request.get(`/admin/order/info/refuse/refund`, { params });
// 订单确认退款
// export const OrderInfoRefundApi = (params) => request.get(`/admin/order/info/refund`, { params });
// 回收权益
// export const RecoveryRightsApi = (params) => request.get(`/admin/order/info/recovery/rights`, { params });
export const RecoveryRights = (params) => request.post(`/adminv2/order/info/recoveryRights`,  params );
// go adminv2 确认退款
export const OrderInfoRefundV2Api = (params) => request.post('/adminv2/order/info/refund',  params );
// adminV2 申请退款
export const ApplyRefundV2Api = (params) => request.post('/adminv2/order/info/applyRefund',  params );
// adminV2 驳回退款
export const refuseRefundV2API = (params) => request.post('/adminv2/order/info/refuseRefund',  params );
// adminV2 添加备注
export const addRemarkFun = (params) => request.post('/adminv2/order/info/addRemark', params);

// 订单数据导入
export const OrderImportApi = (params) => request.post(`/admin/order/info/import`, params);
export const OrderImportApi2 = (params) => request.post(`/adminv2/order/info/import`, params);
// 下载模板
export const DownloadTemplateApi = (params) => request.get(`/admin/order/info/download/template`, { params });
// 设置第三方信息
export const OrderThirdPartyApi = (params) => request.post(`/admin/order/info/third/party`, params);
// 批量开通批改权限
export const OorrectsOpenApi = (params) => request.post(`/admin/corrects/open`, params);
// 订单数据导出
export const OrderInfoExportApi = (params) => request.post(`/admin/order/info/export`, params);
export const OrderInfoExportApi2 = (params) => request.post(`/adminv2/order/info/export`, params);
// 获取可开通批改权限的订单数
export const GetCanBeOpenedCorrectsApi = (params) => request.post(`/admin/order/info/getCanBeOpenedCorrects`, params);
// 全量开通批改权限
export const AllOpenedCorrectsApi = (params) => request.post(`/admin/order/info/allOpenedCorrects`, params);
// 保存修改物流状态
export const SaveDeliveryStatusApi = (params) => request.post(`/admin/order/info/saveDeliveryStatus`, params);


// 查询所有省份
export const ShowAllProvinceApi = (params) => request.get(`/admin/config/select/province`, { params });
// 根据省份code 查询所有市信息
export const GetAllCityByCodeApi = (params) => request.get(`/admin/config/select/city/byProvince`, { params });
// 根据城市code 查询县级信息
export const GetAllCountryByCityApi = (params) => request.get(`/admin/config/select/city/byProvince`, {params});

// 获取第三方订单列表
// export const GetThreeOrdersApi = (params) => request.post(`/admin/order/sync/getOrders`, params);
export const GetThreeOrdersApi = (params) => request.post(`/adminv2/order/sync/getOrders`, params);
// 手动同步订单
export const OrderSyncApi = (params) => request.post(`/admin/order/sync`, params);
// 导出第三方订单
// export const OrderSyncExportApi = (params) => request.post(`/admin/order/sync/export`, params);
export const OrderSyncExportApi = (params) => request.post(`/adminv2/order/sync/export`, params);

// 获取微信小店订单列表
export const GetWxThreeOrdersApi = (params) => request.post(`/admin/wxOrder/getOrders`, params);
// 手动同步微信小店订单
export const WxOrderSyncApi = (params) => request.post(`/admin/wxOrder/sync`, params);
// 导出微信小店订单
export const WxOrderSyncExportApi = (params) => request.post(`/admin/wxOrder/export`, params);
// 修改手机号
export const UpOrderPhoneApi = (params) => request.post(`/adminv2/upOrderPhone`, params);

// 退款列表
export const SsxyTransferSearch = (params) => request.post(`/adminv2/ssxyTransferSearch`, params);

// 第三方绑定 start
// 第三方绑定商品列表
export const relationListAPI = (params) => request.post(`/adminv2/thirdproduct/relationList`, params);
// 关联详情
export const relationDetailAPI = (params) => request.post(`/adminv2/thirdproduct/relationDetail`, params);
// 修改关联
export const editRelationAPI = (params) => request.post(`/adminv2/thirdproduct/editRelation`, params);
// 添加关联
export const addRelationAPI = (params) => request.post(`/adminv2/thirdproduct/addRelation`, params);
// 删除关联
export const delRelationAPI = (params) => request.post(`/adminv2/thirdproduct/delRelation`, params);
// 第三方绑定 end

// 商城订单 start
// 商城订单
export const getShopOrderAPI = (params) => request.post('/adminv2/shoporder/lists', params)
// 下载模版
export const shopOrderDownloadAPI = (params) => request.post('/adminv2/shoporder/download/template', params)
// 修改快递信息
export const updateExpressAPI = (params) => request.post('/adminv2/shoporder/upExpress', params)
// 修改收货信息
export const updateReceiptAPI = (params) => request.post('/adminv2/shoporder/upReceipt', params)
// 申请退款
export const shopOrderRefundAPI = (params) => request.post('/adminv2/shoporder/refund', params)
// 订单导出
export const orderDownAPI = (params) => request.post('/adminv2/shoporder/orderDown', params)
// 商城订单 end

// 倾听者订单start
// 列表
export const getCompayOrdersAPI = (params) => request.post('/adminv2/order/info/getCompayOrders', params)
// 导出
export const exportCompanyAPI = (params) => request.post('/adminv2/order/info/exportCompany', params)
// 倾听者订单end